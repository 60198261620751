import { safelyExtendPrototype } from './extend-prototype';

export function fillLeft(str: string, char: string, totalCount: number): string {
  char = char[0] ?? '?';
  if (totalCount < str.length) {
    return str;
  }

  while (str.length < totalCount) {
    str = char + str;
  }

  return str;
}

export function fillRight(str: string, char: string, totalCount: number): string {
  char = char[0] ?? '?';
  if (totalCount < str.length) {
    return str;
  }

  while (str.length < totalCount) {
    str = str + char;
  }

  return str;
}

declare global {
  interface String {
    characters(): string[];
  }
}

safelyExtendPrototype(String, 'characters', function (): string[] {
  const arr: string[] = [];
  for (let i = 0; i < this.length; i++) {
    arr.push(this[i]);
  }
  return arr;
});
